.show_on_mobile {
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.show_on_desktop {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.gridContainer {
  display: grid;
  margin: 0 auto;
  width: 95%;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  @media screen and (min-width: 768px) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}

.gridHeader {
  color: white;
  text-align: left;
  padding: 8px 8px 16px 0px;
  font-weight: bold;
  border-bottom: 1px dotted pink;

  &:first-child {
    border: none;
  }
}

.gridRowHeader {
  text-align: left;
  color: pink;
  padding: 8px 8px 8px 0px;
}

.gridItem {
  color: rgba(255, 255, 255, 0.9);
  padding: 8px 8px 8px 0px;
  text-align: left;
}

// .keeper_plan {
//   border: 1px solid #9747ff;
//   color: white;
//   padding: 0.25rem 0.5rem;
//   border-radius: 0.25rem;
// }

// .pro_plan {
//   border: 1px solid cyan;
//   color: white;
//   padding: 0.25rem 0.5rem;
//   border-radius: 0.25rem;
// }
