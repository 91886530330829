$brandColor: #ff8b5a;
$backgroundColor: #111113;
$brandFont: 'Inter', sans-serif;
$h1MobileSize: 2rem;
$h1DesktopSize: 4.5rem;
$h2MobileSize: 1.5rem;
$h2DesktopSize: 3rem;
$bodyMobileSize: 1rem;
$bodyDesktopSize: 1.375rem;
$containerWidth: 73.125rem;

.mobileImage {
  position: relative;
  display: block;
  width: 323px;
  height: 484px;
  margin: 0 auto;
}

.desktopImage {
  position: relative;
  display: none;
  width: 980px;
  height: 537px;
  margin: 0 auto;
}

.responsiveImage {
  object-fit: contain;
}

@media (min-width: 768px) {
  .mobileImage {
    display: none;
  }

  .desktopImage {
    display: block;
  }
}

// Other styles
.show_on_mobile {
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.show_on_desktop {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.landing_body {
  background-color: $backgroundColor;
  font-family: $brandFont;
  padding-top: 1rem;

  @media screen and (min-width: 768px) {
    padding-top: 2rem;
  }
}

.landing_nav {
  display: flex;
  padding: 0rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  max-width: $containerWidth;
  margin: 0 auto;

  &_items {
    ul {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5rem;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.landing_button {
  display: inline-flex;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.375rem;
  background: $brandColor;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  color: $backgroundColor;
  text-decoration: none;
  font-size: $bodyMobileSize;

  &:hover {
    cursor: pointer;
  }
}

.redirect_banner {
  background-color: #ff5323;
  padding: 0.75rem;
  text-align: center;
  color: white;
  font-weight: bold;
  transform: translateY(-1rem);

  a {
    color: white;
  }

  @media screen and (min-width: 768px) {
    transform: translateY(-2rem);
  }
}

.desktop_line_break {
  display: none;

  @media screen and (min-width: 768px) {
    display: inline;
  }
}

.intro_section {
  padding: 1rem 0.5rem 0rem;
  text-align: center;
  border-bottom: #3f00c4 1px solid;

  img {
    display: block;
    margin: 0 auto;
  }
}

.intro_heading {
  color: $brandColor;
  font-size: $h1MobileSize;
  margin-bottom: 1.5rem;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;
}

.intro_text {
  color: white;
  font-size: $bodyMobileSize;
  margin-bottom: 1rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .intro_section {
    padding: 0rem;
  }

  .intro_heading {
    font-size: $h1DesktopSize;
    max-width: 80%;
  }

  .intro_text {
    font-size: $bodyDesktopSize;
    max-width: 70%;
  }
}

.feature_section {
  padding: 2rem 1.5rem 1rem;
  text-align: center;
  position: relative;
  margin: 0 auto;
  max-width: $containerWidth;

  h3 {
    color: #b2b3bd;
    margin-bottom: 2rem;
  }

  h2 {
    color: $brandColor;
    font-size: $h2MobileSize;
    margin: 1rem 0rem;
  }

  p {
    color: white;
    font-size: $bodyMobileSize;
  }

  @media screen and (min-width: 768px) {
    padding: 6rem 0px;
    h2 {
      font-size: $h2DesktopSize;
    }

    p {
      font-size: $bodyDesktopSize;
    }
  }
}

.primary_features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5rem;
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.secondary_features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
  }
}

.secondary_features > h2 {
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #f2f2f2;
  text-align: left;
  margin: 1.5rem 0 0;
  padding-left: 1rem;
  align-self: start;
}

.secondary_features > h2:nth-of-type(1) {
  grid-column: 1;
  grid-row: 1;
}

.secondary_features > h2:nth-of-type(2) {
  grid-column: 2;
  grid-row: 1;
}

.secondary_features > h2:nth-of-type(3) {
  grid-column: 3;
  grid-row: 1;
}

.data_export_feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h3 {
    color: var(--Grayscale-White, #fff);
    text-align: center;

    /* H3 */
    font-family: Inter;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.detail_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  &_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 1rem;
    background: $brandColor;

    h3 {
      margin: 0rem;
      font-size: 1.5rem;
    }

    p {
      margin: 0rem;
      font-size: $bodyMobileSize;
    }

    &:nth-last-child(2) {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    display: grid;
    margin: 0 auto;
    gap: 0.75rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-width: $containerWidth;

    &_card {
      height: auto;
      max-height: 196px;
      overflow: hidden;
      gap: 0.75rem;
      align-items: flex-start;

      h3 {
        font-size: 2.5rem;
      }

      p {
        font-size: $bodyDesktopSize;
      }

      &:nth-last-child(2) {
        display: flex;
        flex-direction: row;
      }

      &:last-child {
        height: auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        grid-column: 1 / -1;
      }
    }
  }
}

.comparison_section {
  padding: 3rem 1rem;
  max-width: $containerWidth;
  margin: 0 auto;

  h2 {
    text-align: center;
    color: white;
    font-size: $h2MobileSize;
  }

  &_accordions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    max-width: 80%;
    margin: 0 auto;

    details {
      display: flex;
      padding: 16px 32px 16px 24px;
      align-items: start;
      gap: 8px;
      align-self: stretch;

      border-radius: 12px;
      background-color: #222325;
      color: white;

      &:hover {
        cursor: pointer;
        background-color: #292a2e;
      }
    }

    summary {
      font-size: $bodyMobileSize;
      font-weight: bold;
    }

    ul {
      max-width: 700px;
      line-height: 2rem;
    }
  }

  @media screen and (min-width: 768px) {
    &_accordions {
      summary {
        font-size: $bodyDesktopSize;
      }
    }
  }
}

.suggestions_section {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 90vw;
    max-width: $containerWidth;
    margin: 1rem auto;
    border-radius: 2rem;
    background: linear-gradient(174deg, #ff7033 -15.48%, #3f00c4 108.23%);
    padding: 8.75rem 2rem;
    text-align: center;

    h2 {
      margin-top: 0;
      font-size: $h2MobileSize;
      color: white;
    }

    p {
      font-size: $bodyMobileSize;
      color: white;
    }

    &_cta {
      display: inline-flex;
      padding: 0.5rem 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      border-radius: 1rem;
      border: 1px solid white;
      color: white;
      text-decoration: none;
      font-size: $bodyMobileSize;
      font-weight: bold;
      transition: all 0.25s ease-out;

      &:hover {
        cursor: pointer;
        color: $brandColor;
        border-color: $brandColor;
        padding: 0.5rem 1rem;

        svg > path {
          fill: white;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: $h2DesktopSize;
    }

    p {
      font-size: $bodyDesktopSize;
    }

    &_cta {
      font-size: $bodyDesktopSize;
    }
  }
}

.pricing_section {
  padding: 3rem 0px;
  text-align: center;
  color: $brandColor;
  font-size: $bodyMobileSize;

  p {
    margin: 1rem;
  }

  &_price {
    font-size: 1.5rem;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    padding: 3rem 0rem 5rem;
    font-size: $bodyDesktopSize;

    &_price {
      font-size: 2.5rem;
    }
  }
}
